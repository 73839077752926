import React from 'react'
import Image from 'react-bootstrap/Image'

import './style.css'

function Banner() {

    return (
        <div className="bannerImg">
            <Image src="./images/banner.png" />
        </div>

    )
}

export default Banner